import React, { useEffect, useState } from 'react';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';

import { Button, Navbar } from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Workspace } from 'polotno/canvas/workspace';
import { SidePanel, LayersSection, TextSection, ElementsSection, UploadSection } from 'polotno/side-panel';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { createStore } from 'polotno/model/store';
import { getCustomDesign, UploadDesignImage, UpdateDesignFile } from './Backend';
import { UploadPanel } from './Utils/UploadPanel';

const store = createStore({
  key: process.env.REACT_APP_POLOTNO_API_KEY,
  showCredit: false,
});

UploadSection.Panel = UploadPanel;

const App = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  const tuid = urlParams.get('tuid');
  const marker = urlParams.get('marker');
  const sender = urlParams.get('sender');
  const redirectUrl =
    sender === 'app'
      ? 'https://app.plugger.ai/studio'
      : `https://platform.plugger.ai/assistant?chatUuid=${tuid}`;
  const uniqueImageName =
    (crypto.randomUUID ? crypto.randomUUID() : `${Date.now()}-${Math.floor(Math.random() * 10000)}`) +
    '.png';

  const url = new URL(id);
  const host = url.host;

  // Create a new image URL using the extracted host information.
  // For example, the URL might look like "https://example.com/uniqueImageName.png"
  const newImageUrl = `https://${host}/${uniqueImageName}`;

  const [designSaving, setDesignSaving] = useState(false);

  useEffect(() => {
    if (id) {
      getCustomDesign(id)
        .then(data => {
          if (data) {
            store.setSize(data.width, data.height);

            // Load custom fonts
            if (data.fonts && Array.isArray(data.fonts)) {

              // Add important fonts
              store.addFont({ fontFamily: 'NourdBold', url: 'https://public.plugger.ai/fonts/nourd_regular.ttf' });
              store.addFont({ fontFamily: 'Nourd', url: 'https://public.plugger.ai/fonts/nourd_regular.ttf' });
              // Load design fonts
              data.fonts.forEach(font => {
                store.addFont({ fontFamily: font.family || font.fontFamily, url: font.file || font.url });
              });
            }

            // Create a new page and add elements to it
            const page = store.addPage();

            data.pages.forEach(pageItems => {
              if (pageItems.children) {
                pageItems.children.forEach(element => {
                  page.addElement(element);
                });
              } else {
                page.addElement(pageItems);
              }
            });
          }
        })
        .catch(error => console.error('Error loading custom design:', error));
    } else {
      store.addPage();
    }
  }, [id]);

  const handleClose = () => {
    window.location.href = redirectUrl;
  };

  const handleSave = async () => {
    setDesignSaving(true);
    const fileName = sender === "app" ? uniqueImageName : id.substring(id.lastIndexOf('/') + 1);
    const fileType = "image/png";

    const dataUrl = await store.toDataURL();
    const finalDesign = await store.toJSON();

    UploadDesignImage({ fileName, fileType, location: host }).then((result) => {
      const base64Data = dataUrl.replace(/^data:image\/\w+;base64,/, '');
      const binaryData = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));

      return fetch(result.url, {
        method: 'PUT',
        body: binaryData,
        headers: {
          'Content-Type': fileType,
        },
      });
    }).then(() => {
      const fileUrl = sender === "app" ? newImageUrl : id;

      UpdateDesignFile({ file: fileUrl, dataJson: finalDesign, marker: marker, sender: sender, imageId: id }).then((result) => {
        if (result.status === 200) {
          window.location.href = redirectUrl;
        }
      })
    }).then(() => {
      setDesignSaving(false);
    });
  };

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Navbar>
        <Navbar.Group>
          <Navbar.Heading>Plugger Studio</Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align="right">
          <Button icon="download" text="Save" onClick={handleSave} className='m-2 btn-primary' disabled={designSaving} loading={designSaving} />
          <Button icon="cross" text="Close" onClick={handleClose} disabled={designSaving} loading={designSaving} />
        </Navbar.Group>
      </Navbar>
      <PolotnoContainer style={{ flex: 1, width: '100vw' }}>
        <SidePanelWrap>
          <SidePanel store={store} sections={[LayersSection, TextSection, ElementsSection, UploadSection]} defaultSection="text" />
        </SidePanelWrap>
        <WorkspaceWrap>
          <Toolbar store={store} />
          <Workspace store={store} />
          <ZoomButtons store={store} />
        </WorkspaceWrap>
      </PolotnoContainer>
    </div>
  );
};

export default App;